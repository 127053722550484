import React from 'react';

const IconFont=Loader.loadBaseComponent('IconFont')

export default ({ submit, cancel }) => {
  return (
    <div className="submit-container">
      <div className="ic" onClick={() => cancel()}>
        <IconFont type="icon-S_Photo_MarkNo" />
        <div>取消</div>
      </div>
      <div className="io" onClick={() => submit()}>
        <IconFont type="icon-S_Photo_MarkYes" />
        <div>确定</div>
      </div>
    </div>
  );
};
